import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-card-horizontal',
  templateUrl: './card-horizontal.component.html',
  styleUrls: ['./card-horizontal.component.css']
})
export class CardHorizontalComponent implements OnInit {

  @Input() data:    any;
  @Input() dataOrientation: any;
  @Input() dataDivider:     any;
  @Input() excerpt: any;

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  getBackgroundImage(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`linear-gradient(rgba(29, 29, 29, 0), rgba(16, 16, 23, 0.5)), url(${image})`);
  }

}
