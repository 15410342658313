import { Component, OnInit }  from '@angular/core';
import menu_repositorio_uc    from '../../../assets/json/menu-repositorio.json';
import { Router }             from '@angular/router';
//import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
//import { FormGroup,  FormBuilder,  Validators }   from '@angular/forms';
//import { QueriesService } from '../../services/queries.service';
//importar home.component para mostrar el login en el header 
import { environment }        from "../../../environments/environment";
import CajonBusqueda          from '../../../assets/json/cajon-busqueda.json';
import { DataGlobalService }  from '../../services/data-global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  MenuRepos: any = menu_repositorio_uc['menu-repositorio-uc'];
  MenuCorp: any = menu_repositorio_uc['menu-corp'];
  //data:     any = [];
  msj;
  estilo;
  icono;
  loggedIn: any = false;
  usuario;
  correo;
  editing:  any = false;
  CajonBusqueda: any = CajonBusqueda;

  constructor(
    private router: Router,
    private dataGlobal : DataGlobalService
    //private queriesService: QueriesService, private _sanitizer: DomSanitizer, private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    $("#msjHome").hide();
    if(localStorage.getItem("correo")){
      this.alertas("success","check_circle","Sesión abierta");
    }
  }

  ngDoCheck(){

    // COMPUEBO LOGIN
    if(localStorage.getItem('correo')){
      this.loggedIn = true;
      this.usuario = localStorage.getItem('usuario');
      this.correo = localStorage.getItem('correo');
    }else{
      this.loggedIn = false;
    }

  }
  /* llamarLogin() {
    window.location.href = `${environment.cas_login_url}?service=${encodeURIComponent(window.location.href)}`;
  } */

 
  loginCas(ruta){
    this.dataGlobal.login(ruta);
  }
  logout(){
    this.dataGlobal.logoutCas();
  }

  alertas(estilo, icono, texto){
    $("#msjHome").fadeIn(1000);
    setInterval(() => {
      $("#msjHome").fadeOut(1500);
    }, 2000);
    this.msj = texto;
    this.estilo = estilo;
    this.icono = icono;
  }

  search_header(){
    localStorage.setItem('search_form','');
  }
}


