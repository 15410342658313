import { Component, OnInit } from '@angular/core';
import { DataGlobalService } from '../../../services/data-global.service'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  constructor(
    private dataGlobalService: DataGlobalService
  ) { }

  ngOnInit() {
  }

  pestaniaDeclaracion(event){
    if(!event.target.checked){
      this.dataGlobalService.declaracion = false;
    }
    if(event.target.checked){
      this.dataGlobalService.declaracion = true;
    }
    this.dataGlobalService.modal = false
  }

}
