import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name:"tubos_derecha"
})
export class TubosDerechaPipe implements PipeTransform{

  /* transform(value:string, [separator]):string {
    let splits = value.split(separator);
    if(splits.length > 1) {
      return splits.pop();
    } else {
      return '';
    }
  } */

  transform(value:string) {

    if (value) {
      let evaluar = value.split('|||');
    } else {
      return 
      //console.log('input is falsy');
    }
    // const splits = value.split('|||');
    // if(splits.length > 1) {
    //   return splits.pop();
    // } else {
    //   return value;
    // }
  }

}