import { NgModule }                     from '@angular/core';
import { Routes, RouterModule }         from '@angular/router';

import { AppComponent }                 from './app.component';
import { HomeComponent }                from './templates/home/home.component';
import { ContactComponent }             from './templates/contact/contact.component';
import { CoverSecondLevelComponent }    from './templates/cover-second-level/cover-second-level.component';
import { PageContentComponent }         from './templates/page-content/page-content.component';
import { SingleRecordComponent }        from './templates/single-record/single-record.component';
import { UploadRecordComponent }        from './templates/upload-record/upload-record.component';
import { UploadPublicacionesComponent } from './templates/upload-publicaciones/upload-publicaciones.component';
import { UploadOtrosComponent }         from './templates/upload-otros/upload-otros.component';
import { DatosInvestigacionComponent }  from './templates/datos-investigacion/datos-investigacion.component';
import { PatentesComponent }            from './templates/patentes/patentes.component';
import { CreacionArtisticaComponent }   from './templates/creacion-artistica/creacion-artistica.component';
import { DescargaGaComponent }          from './templates/descarga-ga/descarga-ga.component';
import { BusquedaComponent }            from './templates/busqueda/busqueda.component';
import { IdentityGuard }                from './services/identity.guard';
import { FormdatosGuard }               from './services/formdatos.guard';
import { StatisticsComponent }          from './templates/statistics/statistics.component';
import { VersionesComponent }           from './templates/versiones/versiones.component';

const routes: Routes = [
  {path: '',                   component: HomeComponent },
  {path: 'bibliometrics',loadChildren: () => import('./templates/bibliometrics/bibliometrics.module').then(m => m.BibliometricsModule)},
  {path: 'blog-list',loadChildren: ()     => import('./templates/blog-list/blog-list.module').then(m => m.BlogListModule)},
  {path: 'blog/:id',loadChildren: ()      => import('./templates/blog/blog.module').then(m => m.BlogModule)},
  {path: 'faq',loadChildren: ()           => import('./templates/faq/faq.module').then(m => m.FaqModule)},
  {path: 'enlaces/:pag',loadChildren: ()  => import('./templates/cover-content/cover-content.module').then(m => m.CoverContentModule)},

  { path: 'contact',                              component: ContactComponent },
  { path: 'busqueda',                             component: BusquedaComponent },
  { path: 'busqueda/:select/:option',             component: BusquedaComponent },
  { path: 'busqueda/:select/:option/:valor/:repo',component: BusquedaComponent },
  { path: 'pagina/:pag',                          component: CoverSecondLevelComponent },
  { path: 'upload/:pag',                          component: UploadRecordComponent },
  { path: 'publicaciones',                        component: UploadPublicacionesComponent, canActivate: [FormdatosGuard] },
  { path: 'otros',                                component: UploadOtrosComponent, canActivate: [FormdatosGuard] },
  /* { path: 'datos-investigacion',                  component: DatosInvestigacionComponent, canActivate: [FormdatosGuard] }, */
  /* { path: 'patentes',                             component: PatentesComponent, canActivate: [FormdatosGuard] }, */
  { path: 'creacion-artistica',                   component: CreacionArtisticaComponent, canActivate: [FormdatosGuard] },
  { path: 'content/:pag',                         component: PageContentComponent },
  { path: 'handle/:param1/:param2',               component: SingleRecordComponent },
  { path: 'descarga-ficha/:titulo',               component: DescargaGaComponent },
  { path: 'estadisticas-di',                      component: StatisticsComponent },
  { path: 'versiones',                            component: VersionesComponent },
  
  /* Ruta wildcard, si la ruta no existe, redirige al Home */
  { path: '**',                 redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
