import { Component, OnInit }  from '@angular/core';
import { QueriesService }     from '../../services/queries.service';
import json                   from './contact.json';
import Faqs                   from '../../../assets/json/faq/preguntas-frecuentes.json';
import { DataGlobalService }  from '../../services/data-global.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  urlEmail;
  data: any = [];
  main: any = {};
  response: any = {
    'show': false,
    'data': ''
  }
  name;
  email;
  message;
  public formContactIdHtml:string;
  public formContactIdScript:string;

  constructor(
    private queriesService: QueriesService,
    private dataGlobal : DataGlobalService
    ) { 
    this.urlEmail = this.dataGlobal.apiEmail;
  }

  ngOnInit() {
    
    // refresca página
    this.queriesService.getRefresh();

    this.main = json;
    this.data['faqs'] = Faqs['preguntas-frecuente'];

    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);

    /* SCRIPT FORMULARIO CONTACTO LIBANSWER */
    if(window.location.hostname == "repositorio.uc.cl"){
      this.formContactIdHtml = "form_52ff05d9a0acd0ce1ff4157ace81f76d";
      this.formContactIdScript = "52ff05d9a0acd0ce1ff4157ace81f76d";
/*       console.log("html desde producción", this.formContactIdScript);
      console.log("script desde producción", this.formContactIdScript) */
    }else{
      this.formContactIdHtml = "form_62d06b92acbe839fa1e674d9742ee6cb";
      this.formContactIdScript = "62d06b92acbe839fa1e674d9742ee6cb";
/*       console.log("html distinto a producción", this.formContactIdScript);
      console.log("script distinto a producción", this.formContactIdScript) */
    }

    let body =  document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://uc-cl.libwizard.com/form_loader.php?id='+this.formContactIdScript+'&noheader=1'; /* 'https://uc-cl.libwizard.com/form_loader.php?id=52ff05d9a0acd0ce1ff4157ace81f76d&noheader=1' */
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    
  }

  resetForm() {
    this.response.show = false;
    this.response.text = '';
  }

  submitContact(form, $event) {
    $event.preventDefault();

    if(form.form.value)
      this.response.show = true;
      this.queriesService.queryGet(this.urlEmail+'?nombreApellido='+this.name+'&mail='+this.email+'&consulta='+this.message).then(
      (data) => {
        if(data['respuesta'] == true){
          this.response.show = true;
          this.response.text = 'Su mensaje ha sido enviado con éxito';
          this.response.icono = 'check_circle_outline';
          this.response.color = 'texto-dg';
          //form.form.reset();
          this.name = "";
          this.email = "";
          this.message = "";
        }else{
          //console.log(data);
          this.response.show = true;
          this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
          this.response.icono = 'highlight_off';
          this.response.color = 'texto-dg-rojo';
        }
      },
      (error) => {
        console.log(error);
        this.response.show = true;
        this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
        this.response.icono = 'highlight_off';
        this.response.color = 'texto-dg-rojo';
      }
    );
  }
}
