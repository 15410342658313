import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SubscribeService {
  mailChimpEndpoint = 'https://uc.us5.list-manage.com/subscribe/post-json?u=3019e80d9464546d10c84e3d9&amp;id=79d7a69f29';
  
  constructor(
    private http: HttpClient
  ) { }
  subscribeToList(data) {
    const params = new HttpParams()
      .set('EMAIL', data.email)
      //.set('group[21557][1]', 'true')
      //.set('6308511c04ccbcc46f891a580_beee513b9a', '');
      .set('3019e80d9464546d10c84e3d9&amp;_79d7a69f29', '');
    const mailChimpUrl = `${this.mailChimpEndpoint}&${params.toString()}`;
    return this.http.jsonp(mailChimpUrl, 'c')
  }
}
