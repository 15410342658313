import { Component, OnInit, Input } from '@angular/core';
import { Router }                   from '@angular/router';


@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnInit {

  @Input() data: any;
  @Input() pagina: any;


  constructor(private router: Router) { }

  ngOnInit() {
  }

  getSearch(form, $event) {
    localStorage.setItem('search_form',form.form.value.ucsearch );
    var array_Filtros: any[] = [
      {
        search_by: 'tipo',
        contains: 'contiene',
        term: this.pagina
      }
    ];
    localStorage.setItem('json_filtros',JSON.stringify(array_Filtros));
    localStorage.setItem('searchAdvanced','true');
    localStorage.setItem('page','1');
    this.router.navigate(['/busqueda']);
  }
  BusquedaAvanzada() {
    localStorage.setItem('search_form','');
    var array_Filtros: any[] = [
      {
        search_by: 'tipo',
        contains: 'contiene',
        term: this.pagina
      }
    ];
    localStorage.setItem('json_filtros',JSON.stringify(array_Filtros));
    localStorage.setItem('searchAdvanced','true');
    localStorage.setItem('page','1');
    this.router.navigate(['/busqueda']);
  }
}
