import { Component, OnInit, Input }               from '@angular/core';
import { Router }                                 from '@angular/router';

@Component({
  selector: 'app-card-topic',
  templateUrl: './card-topic.component.html',
  styleUrls: ['./card-topic.component.css']
})
export class CardTopicComponent implements OnInit {

  @Input() cardTopicSelect: any;
  @Input() cardTopicCat: any;
  @Input() cardTopicData: any;
  @Input() cardTopicRepo: any;
  @Input() cardTopicIndex: any;
  @Input() cardTopicImage: any;

  constructor(
    private router: Router,
  ) {}

  ngOnInit() { }

  fotoerror(){
    alert("no hay");
  }

  getSearch(select, cate, topic, repo){
    this.router.navigate(['/busqueda/'+select+'/'+cate[0]+'/'+topic +'/'+repo]);
  }
}
