import { Component, OnInit, Input} from '@angular/core';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);
@Component({
  selector: 'app-card-ods',
  templateUrl: './card-ods.component.html',
  styleUrls: ['./card-ods.component.css']
})
export class CardOdsComponent implements OnInit {

  @Input() cardOdsData: any;
  @Input() cardOdsImage: any;
  @Input () cardOdsSelect: any;
  @Input () cardOdsCat: any;
  @Input () cardOdsRepo: any;
  public numFormat:string;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.numFormat = new Intl.NumberFormat("us-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(this.cardOdsData.cantidad);

    //const numberWithComma = numero; // Un número como string
    //const numberWithDot = numberWithComma.replace(',', '.'); // Reemplaza la coma por un punto
    //this.numFormat = parseFloat(numberWithDot); // Convierte a número
  }

  getSearch(select, cate, data, repo){
    this.router.navigate(['/busqueda/'+select+'/'+cate[0]+'/'+data +'/'+repo]);
    /* console.log("clic en card ods", select, cate, data, repo) */
  }

}
