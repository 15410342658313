import { Component, OnInit, ChangeDetectionStrategy, DoCheck, ɵConsole }  from '@angular/core';
import { animate, state, style, transition, trigger }           from '@angular/animations';
import { Router, ActivatedRoute }                               from '@angular/router';
import * as CryptoJS                                            from 'crypto-js';

import json                                                     from '../../../assets/json/creacion-artistica/creacion-artistica.json';
import compartir                                                from '../../../assets/json/upload-record/02-compartir-investigacion.json';
import { FileuploadService }                                    from '../../servicio/fileupload.service';
import { QueriesService }                                       from '../../services/queries.service';

import { HttpClient } from '@angular/common/http';
import { DataGlobalService }                                    from '../../services/data-global.service';

@Component({
  selector: 'app-creacion-artistica',
  templateUrl: './creacion-artistica.component.html',
  styleUrls: ['./creacion-artistica.component.css'],

  //changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class CreacionArtisticaComponent implements OnInit, DoCheck {
  myFiles:string [] = [];
  public password;
  data: any = [];
  json: any = json;
  compartir: any = compartir;
  isLeftVisible = true;
  activePane: any = 't1';
  array_di: any = {};
  archivo: any = {
    clave: this._queriesService.getPass(),
    user: localStorage.getItem("correo"),
    autor: null,
    titulo: null,
    resultados_investigacion: null,
    fecha_creacion: "0000-00-00",
    enlace_creacion: null,
    nota: null,
    nombreArchivo: null,
    fileSource: null,
    archivos:[],
    base64textString: null,
    requerido: null,
    concedo_licencia: null,
  }
  public formFile = false;
  public array_resumen:any = [];
  public msj: any;
  usuario;
  key = "5t1dd";
  login;
  errLog;
  public identity;
  public token;
  plainText: string;
  encryptText: string;
  encPassword: string;
  decPassword: string;
  conversionEncryptOutput: string;
  conversionDecryptOutput: string;
  soportadateInput;
  today;
  dd;
  mm;
  yyyy;
  rango_date;
  declaracion;
  public modal                    = false;

  constructor(
    private uploadService: FileuploadService,
    private _queriesService: QueriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private dataGlobalService: DataGlobalService
    ) 
    {
      this.identity = this._queriesService.getIdentity();
      this.token = this._queriesService.getToken();
      this.password = this._queriesService.getPass();
  }

  ngOnInit() {
    // refresca página
    /* this._queriesService.getRefresh(); */
    /* this.toScroll(); */
    //document.getElementById('arriba').scrollIntoView({behavior: 'smooth'});
    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);

    this.dataGlobalService.declaracion = false;
    

    this.array_di = this.archivo;
    this.array_di.resultados_investigacion = null;

    if (!this.archivo.licencia) {
      this.archivo.licencia = {}; // Inicialización segura
    } 

    this.date();
    this.today = new Date().toISOString().split('T')[0];

    this.usuario = localStorage.getItem('usuario');
    this.resetForm();
  }

  ngDoCheck(){
    this.password = this._queriesService.getPass()
  }

  date(){
    var pruebaFecha = document.createElement("input");
    //Si tiene soporte: debe aceptar el tipo "date"...
    pruebaFecha.setAttribute("type", "date");
    if ( pruebaFecha.type === "date" ){
      this.soportadateInput = true;
    }else{
      this.soportadateInput = false;
    }
  }

  fechaHoy(){
    var today = new Date().toISOString().split('T')[0];
    document.getElementsByName("input_fecha_publicacion")[0].setAttribute('min', today);
  }

  toScroll(){
    document.getElementById('arriba').scrollIntoView({behavior: 'smooth'});
  }
  
  _handleReaderLoaded(readerEvent) {
    var binaryString = readerEvent.target.result;
    this.archivo.base64textString = btoa(binaryString);
  }

  collapse(item){
    if(item == "general" && this.array_di.autor && this.array_di.titulo && this.array_di.resultados_investigacion){
      $('#'+item).slideToggle(300);
    }
    if(item == "archivos"){
      $('#'+item).slideToggle(300);
    }
    if(item == "licencia" && this.array_di.licencia && this.array_di.licencia.concedo_licencia){
      $('#'+item).slideToggle(300);
    }
  }
  
  seleccionarArchivo(event) {
    this.formFile = true;
    //nuevo
    /* Cargo archivos */
    for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i]);
    }
    /* Escribo archivo en el array con descricion y fecha */
    for (var i = 0; i < event.target.files.length; i++) { 
      this.archivo.archivos.push({
        archivo: event.target.files[i].name
      });
    }

    // viejo
    // if (event.target.files.length > 0) {
    //   const file = event.target.files[0];
    //   this.archivo.fileSource = file;
    //   //console.log(file.name);
    //   console.log("se agrega el archivo", this.archivo.fileSource);
    // }
  }

  resetForm(){
    this.archivo.fileSource = "";
    this.archivo.nombreArchivo = "";
    this.archivo.autor = "";
    this.archivo.titulo = "";
    this.archivo.resultados_investigacion = null;
    this.archivo.requerido = null;
    this.archivo.licencia.concedo_licencia =  null;
    this.archivo.licencia.creative_commons = null;
    this.archivo.licencia.url = null;
    for(var i = 0; this.json['horizon_forms']['content'][0]['sections'][0]['input_resultado_investigacion']['options'].length>i; i++){    
      this.json['horizon_forms']['content'][0]['sections'][0]['input_resultado_investigacion']['options'][i].cheq = false
    }
  }
  resetFile(){
    /* Por errores al volver atrás, se resetea el archivo */
    this.archivo.fileSource = null;
    this.archivo.nombreArchivo = null;
  }
  // Nuevo carga
  submitPublica(form){
  
    this.login = false;
    
    const formData = new FormData();
    
    /* Entrego archivos */
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i]);
    }

    /* LIMPIO ARRAY */
    let limpiar_array = this.archivo;
    for(let i in limpiar_array){
      if(limpiar_array[i] == null || limpiar_array[i] == ''){
        delete limpiar_array[i];
      }
    }

    /* console.log(this.dataGlobalService.apiUploadCreacionArtistica);
    console.log(this.myFiles)
    console.log("array_datos", limpiar_array); */

    formData.append('array_datos', JSON.stringify(limpiar_array));

    /* return */
    this.http.post(this.dataGlobalService.apiUploadCreacionArtistica, formData)
      .subscribe(response => {
        if(response['httpCode'] == "200"){
          /* console.log("this.urlfile, formData", this.dataGlobalService.apiUploadCreacionArtistica, formData) */
          this.login = false;
          this.msj = "success";
          /* console.log("respuesta en if", response); */
          
        }else if(response['httpCode']== "403"){
          this.msj = false;
          this.login = true;
          this.errLog = true;
          this.archivo.clave = null;
          console.log("se conectó pero login es incorrecto");
          console.log(response['resultado'])
          
        }else{
          this.msj = "error";
          form.reset();
          this.archivo.nombreArchivo= null;
          this.archivo.base64textString= null;
          this.archivo.requerido= false;
          console.log("se conectó pero no trajo resultado 200");
          console.log(response, "Trae archivo en error?", this.archivo.nombreArchivo)
        }
      })

  }
  loguearse(){
    this.login = true;
    this.errLog = false;
  }
  closeLogin(){
    this.login = false;
  }
  _modal(){

    var modal = document.getElementById("tvesModal");
    var body = document.getElementsByTagName("body")[0];

    modal.style.display = "block";
    body.style.position = "static";
    body.style.height = "100%";
    body.style.overflow = "hidden";

        window.onclick = function(event) {
          if (event.target == modal) {
            modal.style.display = "none";

            body.style.position = "inherit";
            body.style.height = "auto";
            body.style.overflow = "visible";
          }
        }
  }
  pestaniaDeclaracion(event){
    if(!event.target.checked){
      this.declaracion = false;
    }
    if(event.target.checked){
      this.declaracion = true;
    }
    this.modal = false
  }
  closeModal(){
    var modal = document.getElementById("tvesModal");
    var body = document.getElementsByTagName("body")[0];

    modal.style.display = "none";
    body.style.position = "inherit";
    body.style.height = "auto";
    body.style.overflow = "visible";

  }
  newUpload(form){
    this.msj= "";
    this.activePane= 't1';
  }
  tabla_resumen(){
    this.array_resumen = [];
    var alias;
    var alias_valor;
    var mostrar = true;
    var subitem = false;

    for(let i in this.archivo){
      if(i == "autor"){
        alias = "Autor principal";
        mostrar = true;
        alias_valor = this.archivo[i];
        subitem = false;
      }
      if(i == "titulo"){
        alias = "Título";
        alias_valor = this.archivo[i];
        mostrar = true;
        subitem = false;
      }
      if(i == "resultados_investigacion"){
        alias = "Resultados de investigación";
        alias_valor = this.archivo[i];
        mostrar = true;
        subitem = true;
      }
      if(i == "fecha_creacion"){
        alias = "Fecha de creación";
        alias_valor = this.archivo[i];
        mostrar = true;
        subitem = false;
      }
      if(i == "enlace_creacion"){
        alias = "Enlace de creación";
        alias_valor = this.archivo[i];
        mostrar = true;
        subitem = false;
      }
      if(i == "nota"){
        alias = "Nota";
        alias_valor = this.archivo[i];
        mostrar = true;
        subitem = false;
      }
      if(i == "clave"){
        alias = "Clave";
        mostrar = false;
        subitem = false;
      }
      if(i == "user"){
        alias = "Usuario";
        mostrar = false;
        subitem = false;
      }
      if(i == "fecha"){
        alias = "Fecha de embargo";
        alias_valor = this.archivo[i];
        mostrar = false;
        subitem = false;
      }
      if(i == "nombreArchivo"){
        alias = "Nombre del archivo";
        alias_valor = this.archivo[i];
        mostrar = true;
        subitem = false;
      }
      if(i == "fileSource"){
        alias = "fileSource";
        mostrar = false;
        subitem = false;
      }
      if(i == "base64textString"){
        alias = "base64textString";
        mostrar = false;
        subitem = false;
      }
      if(i == "licencia"){
        alias = "Licencia";
        alias_valor = this.archivo[i];
        mostrar = true;
        subitem = false;
      }

      if(mostrar == true){
        this.array_resumen.push( { "param":alias, "subitem":subitem, "valor": alias_valor});
      }
    }
  }
  cheq_resultado_investigacion(event, ind:number){
    var lista = this.json['horizon_forms']['content'][0]['sections'][0]['input_resultado_investigacion']['options'];
    
    if(event.target.checked){
      if(!this.array_di.resultados_investigacion){
        this.array_di.resultados_investigacion = [];
      }
      this.array_di.resultados_investigacion.push(
        event.target.value
      );
      lista[ind].cheq = true;
    }else{
      for(var i = 0; this.array_di.resultados_investigacion.length>i; i++){    
        if(this.array_di.resultados_investigacion[i]==event.target.value){
          this.array_di.resultados_investigacion.splice(i, 1);
        }
      }
      if(this.array_di.resultados_investigacion.length == 0){
        this.array_di.resultados_investigacion = null;
      }
      lista[ind].cheq = false;
    }
  }
}
