import { Component, OnInit, DoCheck }         from '@angular/core';
import { QueriesService }                     from '../../services/queries.service';
import { Router, ActivatedRoute, Params }     from '@angular/router';
import Json                                   from '../../../assets/json/template-pagina/cover-second-level.json';
import busqueda_tesis                         from '../../../assets/json/template-pagina/05-busqueda-tesis.json';
import compartir                              from '../../../assets/json/template-pagina/02-compartir-investigacion.json';
import { DataGlobalService }                  from '../../services/data-global.service';

@Component({
  selector: 'app-cover-second-level',
  templateUrl: './cover-second-level.component.html',
  styleUrls: ['./cover-second-level.component.css']
})
export class CoverSecondLevelComponent implements OnInit, DoCheck {

  data:           any = [];
  main:           any;
  json:           any = Json;
  busqueda_tesis: any = busqueda_tesis;
  compartir:      any = compartir;

  
  public pagina:  string;
  public term = "tesis";
  public loggedIn = false;

  constructor(
    private queriesService: QueriesService,
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private dataGlobal : DataGlobalService
    ) { 
    }

  ngOnInit() {

    // refresca página
    this.queriesService.getRefresh();

    /* RECIBE PARAMETRO DE PÁGINA POR URL */
    this.activatedRoute.params.subscribe(params =>{
      this.pagina = params['pag'];
      // Scroll
      document.getElementById('arriba').scrollIntoView({behavior: 'smooth'}); 
      
      this.postend();

      // JQuery ir arriba
      /* $('body, html').animate({
        scrollTop: '0px'
      }, 300); */

      
    });

    

  }
  ngDoCheck(){
    this.json = Json;
    this.paginas();


    if(localStorage.getItem('usuario') || localStorage.getItem('correo')){
      this.loggedIn = true;
    }else{
      this.loggedIn = false;
    }

  }

  paginas(){
    
    
      // MENU TESIS
      if(this.pagina == "tesis"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      if(this.pagina == "trabajos-graduacion"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      // MENU PUBLICACIONES
      if(this.pagina == "articulos"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      if(this.pagina == "capitulos-libros"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      if(this.pagina == "libros"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      if(this.pagina == "documentos-trabajo"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      if(this.pagina == "presentaciones-congresos"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      if(this.pagina == "obras-artisticas"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      if(this.pagina == "otros"){
        this.json = this.json[this.pagina ];
        this.main = Json[this.pagina];
      }
      if(this.pagina == "conjuntos-de-datos") {
        this.json = this.json[this.pagina];
        this.main = Json[this.pagina];
      }
      
      
  }
  postend(){
    /* 10 últimas publicaciones  */
    /* var str_filtros = "fq=dc.type:"+this.pagina+"%26"; */
    /* var str_filtros = this.pagina;
    var urlFiltro = this.urlPhp + 'filtro=' + str_filtros; */
    /* if(this.pagina == "datos-investigacion"){
      this.pagina = 'conjuntos+de+datos';
    } */

    this.queriesService.queryGet( this.dataGlobal.apiUltimosAgregados + 'tipo=' +  this.pagina)
      .then((data) => { 
        this.data['search']= Object.keys(data) .map(i =>data[i]);
        this.data['search']= this.data['search'][1]['docs'];


    });
  }
  
  loginCas(ruta){
    window.location.href='assets/php/cas-log/cas-login.php?ruta='+ruta;;
  }

}
