import { Component, OnInit, ChangeDetectionStrategy, DoCheck, ɵConsole }  from '@angular/core';
import { animate, state, style, transition, trigger }           from '@angular/animations';
import { Router, ActivatedRoute }                               from '@angular/router';
import json                                                     from '../../../assets/json/patentes/patentes.json';
import compartir                                                from '../../../assets/json/upload-record/02-compartir-investigacion.json';
import { FileuploadService }                                    from '../../servicio/fileupload.service';
import { QueriesService }                                       from '../../services/queries.service';
import { DataGlobalService }                                    from '../../services/data-global.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-patentes',
  templateUrl: './patentes.component.html',
  styleUrls: ['./patentes.component.css'],
  animations: [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class PatentesComponent implements OnInit, DoCheck {
  myFiles: File[] = [];

  metadata: any =[];

  public password;
  public data: any = [];
  public json: any = json;
  public main: any = json;
  public compartir: any = compartir;
  public activePane: any = 't1';
  public tipo_responsable = "Persona";
  public array_di: any = 
    {
      clave: this._queriesService.getPass(),
      user: localStorage.getItem("correo"),
      autor: null,
      titulo: null,
      unidad: null,
      solicitante: null,
      destinatario: null,
      financiamiento: null,
      fecha_concesion: "0000-00-00",
      n_concesion: null,
      pais: null,
      estado_patentamiento: null,
      nro_solicitud: null,
      territorialidad: null,
      tipo_presentacion: null,
      archivos:[],
      concedo_licencia: null,
    };
  public array_resumen:any = [];
  public formFile = false;
  public msj: any;
  public usuario;
  public login;
  public errLog;
  public identity;
  public token;
  public soportadateInput;
  public statusresponsable;
  today;
  rango_date;

  constructor(
    private uploadService: FileuploadService,
    private _queriesService: QueriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private dataGlobalService: DataGlobalService
    ) 
    {
      this.identity = this._queriesService.getIdentity();
      this.token = this._queriesService.getToken();
      this.password = this._queriesService.getPass();
    }

  ngOnInit() {
    
    if (!this.array_di.licencia) {
      this.array_di.licencia = {}; // Inicialización segura
    } 
    
    // refresca página
    this._queriesService.getRefresh();
    this.date();
    this.today = new Date().toISOString().split('T')[0];
    this.rango_date = true;
    this.usuario = localStorage.getItem('usuario');

    // JQuery ir arriba
      $('body, html').animate({
        scrollTop: '0px'
      }, 300);
  }

  ngDoCheck(){
    this.password = this._queriesService.getPass()
  }
  removeFile(i: number) {
    this.array_di.archivos.splice(i, 1); // elimina 1 indice a partir del indice i
  }
  date(){
    var pruebaFecha = document.createElement("input");
    //Si tiene soporte: debe aceptar el tipo "date"...
    pruebaFecha.setAttribute("type", "date");
    if ( pruebaFecha.type === "date" ){
      this.soportadateInput = true;
    }else{
      this.soportadateInput = false;
    }
  }
  loguearse(){
    this.login = true;
    this.errLog = false;
  }

  closeLogin(){
    this.login = false;
  }

  territ(opcion){
    console.log(opcion);
    this.array_di.territorialidad = opcion;
    this.array_di.tipo_presentacion = null;
    if(opcion == "Extranjera"){
      this.array_di.tipo_presentacion = opcion;
    }
  }
  tipo_presentacion(opcion){
    console.log(opcion);
    this.array_di.tipo_presentacion = opcion;
    
  }

  seleccionarArchivo(event) {
    console.log("archivo evento", event);
    this.formFile = true;
    /* Cargo archivos */
    for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i]);

      // Agregar archivo al array de archivos con su descripción
      this.array_di.archivos.push({
        archivo: event.target.files[i].name
      });
      console.log("se llenó el array de archivos", this.array_di.archivos);
    }
  }

  // ENVIO
  submitPublica(form){ 
    this.login = false;
    this.msj = "cargando";
    if(this.array_di.fecha_concesion == "0000-00-00"){this.array_di.fecha_concesion = 0}

    const formData = new FormData();

    /* Entrego archivos */
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i]);
    }
    

    /* LIMPIO ARRAY */
    let limpiar_array = this.array_di;
    for(let i in limpiar_array){
      if(limpiar_array[i] == null || limpiar_array[i] == ''){
        delete limpiar_array[i];
      }
    }

    formData.append('array_datos', JSON.stringify(limpiar_array));
 
    this.http.post(this.dataGlobalService.apiUploadPatentes, formData)
    .subscribe(response => {
      console.log(response)

      if(response['httpCode'] == "200"){
              this.login = false;
              this.msj = "success";
              this.array_di.archivos[0].archivo= null;
              this.array_di.concedo_licencia = false;
              console.log(response);
              
      }else if(response['httpCode'] == "403"){
              this.login = true;
              this.msj = false;
              this.errLog = true;
              this.array_di.clave = null;
              console.log("se conectó pero login es incorrecto");
              console.log(response);
              
      }else{
              this.msj = "error";
              form.reset();
              this.array_di.archivos[0].archivo= null;
              this.array_di.archivos[0].base64textString= null;
              this.array_di.concedo_licencia= false;
              console.log("se conectó pero no trajo resultado 200");
              console.log(response)
      }
    })

  }

  resetFile(){
    for (var i = 0; i < this.array_di.archivos.length; i++) { 
      this.array_di.archivos[i].archivo = null;
    }
  }

  newUpload(form){
    this.msj= "";
    this.activePane= 't1';
    location.reload();
  }

  toScroll(){
    document.getElementById('pasos').scrollIntoView({behavior: 'smooth'});
  }

  tabla_resumen() {
    this.array_resumen = [];
    var array = this.array_di;
    var alias;
    var alias_valor;
    var mostrar = true;
    for(let i in array){
      if(i == "clave" || i == "user" || i == "fileSource" || i == "base64textString"){
        mostrar = false; // No mostrar estos campos
      } else {
        mostrar = true; // Mostrar todos los demás campos
        if(i == "titulo"){
          alias = "Título";
          alias_valor = array[i];
        }
        if(i == "autor"){
          alias = "Autor";
          alias_valor = array[i];
        }
        if(i == "unidad"){
          alias = "Unidad";
          alias_valor = array[i];
        }
        if(i == "solicitante"){
          alias = "Solicitante";
          alias_valor = array[i];
        }
        if(i == "destinatario"){
          alias = "Destinatario";
          alias_valor = array[i];
        }
        if(i == "financiamiento"){
          alias = "Financiamiento";
          alias_valor = array[i];
        }
        if(i == "fecha_concesion"){
          alias = "Fecha de Concesión";
          alias_valor = array[i];
        }
        if(i == "n_concesion"){
          alias = "Número de Concesión";
          alias_valor = array[i];
        }
        if(i == "pais"){
          alias = "País";
          alias_valor = array[i];
        }
        if(i == "estado_patentamiento"){
          alias = "Estado de Patentamiento";
          alias_valor = array[i];
        }
        if(i == "nro_solicitud"){
          alias = "Número de Solicitud";
          alias_valor = array[i];
        }
        if(i == "territorialidad"){
          alias = "Territorialidad";
          alias_valor = array[i];
        }
        if(i == "tipo_presentacion"){
          alias = "Tipo de Presentación";
          alias_valor = array[i];
        }
        if(i == "archivos"){
          alias = "Archivos";
          alias_valor = array[i];
          console.log("archivos en if", array[i]);
        }
      
        if(i == "licencia"){
          alias = "Licencia";
          mostrar = true;
          if(array[i].concedo_licencia == true){
            alias_valor = array[i].creative_commons;
          }
        }
      }
      if(mostrar){
        this.array_resumen.push({ "param": alias, "valor": alias_valor });
      }
    }
    console.log("tabla resumen patente", this.array_resumen);
  }

  
}
