import { Component, OnInit, ChangeDetectionStrategy, DoCheck, ɵConsole }  from '@angular/core';
import { animate, state, style, transition, trigger }           from '@angular/animations';
import { Router, ActivatedRoute }                               from '@angular/router';
import * as CryptoJS                                            from 'crypto-js';

import json                                                     from '../../../assets/json/upload-record/upload-record.json';
import { FileuploadService }                                    from '../../servicio/fileupload.service';
import { QueriesService }                                       from '../../services/queries.service';
import { DataGlobalService }                                    from '../../services/data-global.service';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-upload-record',
  templateUrl: './upload-record.component.html',
  styleUrls: ['./upload-record.component.css'],
  animations: [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class UploadRecordComponent implements OnInit, DoCheck {
  loggedIn: any = false;
  public password;
  json: any = json;
  login;
  public identity;
  public token;

  constructor(
    private uploadService: FileuploadService,
    private _queriesService: QueriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private dataGlobal : DataGlobalService
    ) 
    {
      this.identity = this._queriesService.getIdentity();
      this.token = this._queriesService.getToken();
      this.password = this._queriesService.getPass();
  }
  ngOnInit() {
    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);
  }
  ngDoCheck(){
    this.password = this._queriesService.getPass()
  }

  toScroll(){
    document.getElementById('pasos').scrollIntoView({behavior: 'smooth'});
  }

  irPagForm(ruta: string) {
    this.dataGlobal.login(ruta)
  }
}
