import { Component, OnInit, ChangeDetectionStrategy, DoCheck, ɵConsole }  from '@angular/core';
import { animate, state, style, transition, trigger }           from '@angular/animations';
import { Router, ActivatedRoute }                               from '@angular/router';
import json                                                     from '../../../assets/json/datos-investigacion/datos-investigacion.json';
import compartir                                                from '../../../assets/json/upload-record/02-compartir-investigacion.json';
import { FileuploadService }                                    from '../../servicio/fileupload.service';
import { QueriesService }                                       from '../../services/queries.service';
import { HttpClient } from '@angular/common/http';
import { DataGlobalService }                      from '../../services/data-global.service';

@Component({
  selector: 'app-datos-investigacion',
  templateUrl: './datos-investigacion.component.html',
  styleUrls: ['./datos-investigacion.component.css'],
  animations: [
    trigger('slide', [
      state('t1', style({ transform: 'translateX(0)' })),
      state('t2', style({ transform: 'translateX(-25%)' })),
      state('t3', style({ transform: 'translateX(-50%)' })),
      state('t4', style({ transform: 'translateX(-75%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class DatosInvestigacionComponent implements OnInit, DoCheck {
  myFiles:string [] = [];
  metadata: any =[];

  public password;
  public data: any = [];
  public json: any = json;
  public main: any = json;
  public compartir: any = compartir;
  public activePane: any = 't1';
  public tipo_responsable = "Persona";
  public cc_compartir = "cc_sicomparte";
  public cc_comercial = "cc_sicomercial";
  public titulo_cc = "Licencia Creative Commons Atribución 4.0 Internacional";
  public url_cc = "https://creativecommons.org/licenses/by/4.0/legalcode.es";
  public img_cc = "../../../assets/img/cc_40.png";
  public img_licencia = "../../../assets/img/seal.png";
  public descripcion_licencia = "¡Esta es una Licencia de Cultura Libre!";
  public array_di: any = 
    {
      clave: this._queriesService.getPass(),
      user: localStorage.getItem("correo"),
      titulo: null,
      resumen: null,
      keyword: null,
      editorial: null,
      categorias: [],
      licencia:{
        concedo_licencia: null,
        creative_commons: this.titulo_cc,
        url: this.url_cc
      },
      responsable:[{
        apellido:null,
        nombre:null,
        nombre_unidad:null
      }],
      publicaciones_relacionadas:[{
        titulo:"",
        url:""
      }],
      archivos:[{
        archivo: ''/* ,
        descripcion_archivo: '',
        fecha_embargo: '' */
      }],
      fecha_embargo: "0000-00-00"
    };
  public array_resumen:any = [];
  public formFile = false;
  public msj: any;
  public usuario;
  public login;
  public errLog;
  public identity;
  public token;
  public soportadateInput;
  public statusresponsable;

  today;
  rango_date;

  constructor(
    private uploadService: FileuploadService,
    private _queriesService: QueriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private dataGlobal : DataGlobalService
    ) 
    {
      this.identity = this._queriesService.getIdentity();
      this.token = this._queriesService.getToken();
      this.password = this._queriesService.getPass();
    }


  ngOnInit() {
    // refresca página
    this._queriesService.getRefresh();
    this.date();
    this.today = new Date().toISOString().split('T')[0];
    this.rango_date = true;
    this.usuario = localStorage.getItem('usuario');

    // JQuery ir arriba
      $('body, html').animate({
        scrollTop: '0px'
      }, 300);
  }

  ngDoCheck(){
    this.password = this._queriesService.getPass()
  }

  autor(dato:string){
    if(this.array_di.responsable[0].apellido && this.array_di.responsable[0].nombre || this.array_di.responsable[0].nombre_unidad) {
      this.statusresponsable= true; 
    }else{
      this.statusresponsable= false; 
    }

    if(dato=="persona"){   
      /* this.array_di.responsable.persona.splice(0);
      this.addPersona();
      this.array_di.responsable.unidad.splice(0);
      this.addUnidad(); */
      /* this.array_di.responsable.unidad[0].nombre_unidad= " "; */
      
    }
    if(dato=="unidad"){
      /* this.array_di.responsable.unidad.splice(0);
      this.addUnidad()
      this.array_di.responsable.persona.splice(0);
      this.addPersona() */
      /* this.array_di.responsable.persona[0].nombre= " ";
      this.array_di.responsable.persona[0].apellido= " "; */
    }
  }

  addPersona() {  
    this.array_di.responsable.push({
      nombre: '',
      apellido: '',
      nombre_unidad: ''
    });
  }

  removePersona(i: number) {
    this.array_di.responsable.splice(i, 1); // elimina 1 indice a partir del indice i
  }

/*   addUnidad() {  
    this.array_di.responsable.unidad.push({
      nombre_unidad: ''
    });
  }
  removeUnidad(i: number) {
    this.array_di.responsable.unidad.splice(i, 1); 
  } */

  addPubliRel() {  
    this.array_di.publicaciones_relacionadas.push({
      titulo: '',
      url: ''
    });
  }
  removePubliRel(i: number) {
    this.array_di.publicaciones_relacionadas.splice(i, 1); // elimina 1 indice a partir del indice i
  }

  removeFile(i: number) {
    this.array_di.archivos.splice(i, 1); // elimina 1 indice a partir del indice i
  }
  
  // cc(data){
  //   if(data == "cc_sicomparte" && this.cc_comercial == "cc_sicomercial" || data == "cc_sicomercial" && this.cc_compartir == "cc_sicomparte"){
  //     this.titulo_cc = "Licencia Creative Commons Atribución 4.0 Internacional";
  //     var titulo_dc_right = "Atribución 4.0 Internacional (CC BY 4.0)";
  //     this.url_cc = "https://creativecommons.org/licenses/by/4.0/legalcode.es";
  //     this.img_cc = "../../../assets/img/cc_40.png";
  //     this.img_licencia = "../../../assets/img/seal.png";
  //     this.descripcion_licencia = "¡Esta es una Licencia de Cultura Libre!";
  //     this.array_di.licencia.creative_commons = titulo_dc_right;
  //     this.array_di.licencia.url = this.url_cc;
  //   }
  //   if(data == "cc_sicondicion" && this.cc_comercial == "cc_sicomercial" || data == "cc_sicomercial" && this.cc_compartir == "cc_sicondicion"){
  //     this.url_cc = "https://creativecommons.org/licenses/by-sa/4.0/legalcode.es";
  //     this.titulo_cc = "Licencia Creative Commons Atribución-CompartirIgual 4.0 Internacional";
  //     var titulo_dc_right = "Atribución-CompartirIgual 4.0 Internacional (CC BY-SA 4.0)";
  //     this.img_cc = "../../../assets/img/cc_sa40.png";
  //     this.img_licencia = "../../../assets/img/seal.png";
  //     this.descripcion_licencia = "¡Esta es una Licencia de Cultura Libre!";
  //     this.array_di.licencia.creative_commons = titulo_dc_right;
  //     this.array_di.licencia.url = this.url_cc;
  //   }
  //   if(data == "cc_nocomparte" && this.cc_comercial == 'cc_sicomercial' || data == "cc_sicomercial" && this.cc_compartir == "cc_nocomparte"){
  //     this.url_cc = "https://creativecommons.org/licenses/by-nd/4.0/legalcode.es";
  //     this.titulo_cc = "Licencia Creative Commons Atribución-SinDerivadas 4.0 Internacional";
  //     var titulo_dc_right = "Atribución-SinDerivadas 4.0 Internacional (CC BY-ND 4.0)";
  //     this.img_cc = "../../../assets/img/cc_nd40.png";
  //     this.img_licencia = "../../../assets/img/no_aprueba.png";
  //     this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
  //     this.array_di.licencia.creative_commons = titulo_dc_right;
  //     this.array_di.licencia.url = this.url_cc;
  //   }

  //   if(data == "cc_nocomercial" && this.cc_compartir == "cc_sicomparte" || data == "cc_sicomparte" && this.cc_comercial == "cc_nocomercial"){
  //     this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial 4.0 Internacional";
  //     var titulo_dc_right = "Atribución-NoComercial 4.0 Internacional (CC BY-NC 4.0)";
  //     this.url_cc = "https://creativecommons.org/licenses/by-nc/4.0/legalcode.es";
  //     this.img_cc = "../../../assets/img/cc_nc_40.png";
  //     this.img_licencia = "../../../assets/img/no_aprueba.png";
  //     this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
  //     this.array_di.licencia.creative_commons = titulo_dc_right;
  //     this.array_di.licencia.url = this.url_cc;
  //   }
  //   if(data == "cc_nocomercial" && this.cc_compartir == "cc_sicondicion" || data == "cc_sicondicion" && this.cc_comercial == "cc_nocomercial"){
  //     this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial-CompartirIgual 4.0 Internacional";
  //     var titulo_dc_right = "Atribución-NoComercial-CompartirIgual 4.0 Internacional (CC BY-NC-SA 4.0)";
  //     this.url_cc = "https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode.es";
  //     this.img_cc = "../../../assets/img/cc_nc_sa40.png";
  //     this.img_licencia = "../../../assets/img/no_aprueba.png";
  //     this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
  //     this.array_di.licencia.creative_commons = titulo_dc_right;
  //     this.array_di.licencia.url = this.url_cc;
  //   }
  //   if(data == "cc_nocomercial" && this.cc_compartir == "cc_nocomparte" || data == "cc_nocomparte" && this.cc_comercial == "cc_nocomercial"){
  //     this.titulo_cc = "Licencia Creative Commons Atribución-NoComercial-SinDerivadas 4.0 Internacional";
  //     var titulo_dc_right = "Atribución-NoComercial-SinDerivadas 4.0 Internacional (CC BY-NC-ND 4.0)";
  //     this.url_cc = "https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode.es";
  //     this.img_cc = "../../../assets/img/cc_nc_nd40.png";
  //     this.img_licencia = "../../../assets/img/no_aprueba.png";
  //     this.descripcion_licencia = "¡Esta no es una Licencia de Cultura Libre!";
  //     this.array_di.licencia.creative_commons = titulo_dc_right;
  //     this.array_di.licencia.url = this.url_cc;
  //   }
  //   console.log(this.array_di)
  // }

  //MDatos de componente recibidos de cc.component.ts
  
  handleCcChange(event: any) {
    console.log("Datos recibidos del CcComponent:", event.data);
    this.array_di = event.data;
  }

  tabla_resumen(){
    this.array_resumen = [];
    var array = this.array_di;
    var alias;
    var alias_valor;
    var mostrar = true;
    for(let i in array){
      
      if(i == "titulo"){
        alias = "Título";
        alias_valor = array[i];
        mostrar = true;
      }
      if(i == "resumen"){
        alias = "Descripción";
        alias_valor = array[i];
        mostrar = true;
      }
      if(i == "keyword"){
        alias = "Palabras claves";
        mostrar = true;
        alias_valor = array[i];
      }
      if(i == "editorial"){
        alias = "Editorial";
        mostrar = true;
        alias_valor = array[i];
      }
      if(i == "categorias"){
        alias = "Categorías";
        mostrar = true;
        alias_valor = array[i];
      }

      if(i == "clave"){
        alias = "Clave";
        mostrar = false;
        alias_valor = array[i];
      }
      if(i == "user"){
        alias = "Usuario";
        mostrar = false;
      }
      if(i == "fecha_embargo"){
        alias = "Fecha de embargo";
        alias_valor = array[i];
        mostrar = true;
      }
      if(i == "fileSource"){
        alias = "fileSource";
        mostrar = false;
      }
      if(i == "base64textString"){
        alias = "base64textString";
        mostrar = false;
      }
      if(i == "licencia"){
        alias = "Licencia";
        mostrar = true;
        if(array[i].concedo_licencia == true){
          alias_valor = array[i].creative_commons;
        }
      }
      if(i == "responsable"){
        alias = "Responsable de la creación de los datos";
        mostrar = true;
        alias_valor = array[i][0].apellido +', '+ array[i][0].nombre +'. Unidad/Centro/Departamento: '+ array[i][0].nombre_unidad;
      }
      if(i == "publicaciones_relacionadas"){
        alias = "Publicaciones Relacionadas";
        mostrar = true;
        if(array[i][0].titulo && array[i][0].url){
          alias_valor = array[i][0].titulo +', '+ array[i][0].url;
        }else{
          alias_valor = array[i][0].titulo + array[i][0].url;
        }
      }

      if(i == "archivos"){
        alias = "Archivos";
        mostrar = true;
        alias_valor = array[i];
      }
      if(mostrar == true){
        this.array_resumen.push( { "param":alias, "valor": alias_valor});
      }
    }
console.log(this.array_resumen)
  }

  collapse(item){
    $('#'+item).slideToggle(300);
    this.tabla_resumen()
  }

  date(){
    var pruebaFecha = document.createElement("input");
    //Si tiene soporte: debe aceptar el tipo "date"...
    pruebaFecha.setAttribute("type", "date");
    if ( pruebaFecha.type === "date" ){
      this.soportadateInput = true;
    }else{
      this.soportadateInput = false;
    }
  }
  
  loguearse(){
    this.login = true;
    this.errLog = false;
  }

  closeLogin(){
    this.login = false;
  }
     
  cheq(event){
    if(event.target.checked){
      this.array_di.categorias.push(
        event.target.value
      );
    }else{
      for(var i = 0; this.array_di.categorias.length>i; i++){    
        if(this.array_di.categorias[i]==event.target.value){
          this.array_di.categorias.splice(i, 1);
        }
      }
    }
  }

  seleccionarArchivo(event) {
    
    this.formFile = true;
    /* Cargo archivos */
    for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i]);
    }
    /* Escribo archivo en el array con descricion y fecha */
    for (var i = 0; i < event.target.files.length; i++) { 
      this.array_di.archivos.push({
        archivo: event.target.files[i].name/* ,
        descripcion_archivo: '',
        fecha_embargo: '0' */
      });
    }
  }
  
  // CARGA DI
  submitPublica(form){ 
    this.login = false;
    this.msj = "cargando";
    if(this.array_di.fecha_embargo == "0000-00-00"){this.array_di.fecha_embargo = 0}
   
    console.log(this.myFiles);
    console.log(this.array_di);
    
    const formData = new FormData();
    /* Entrgo metadatos */
    formData.append('clave', this.array_di.clave);
    formData.append('user', this.array_di.user);
    formData.append('titulo', this.array_di.titulo);
    formData.append('resumen', this.array_di.resumen);
    formData.append('keyword', this.array_di.keyword);
    formData.append('editorial', this.array_di.editorial);
    formData.append('licencia', JSON.stringify(this.array_di.licencia));
    formData.append('fecha_embargo', this.array_di.fecha_embargo);
    formData.append('persona', JSON.stringify(this.array_di.responsable));
    /* formData.append('responsable', JSON.stringify(this.array_di.responsable)); */
    //formData.append('responsable', JSON.stringify(this.array_di.responsable.unidad));
    
    var titulo= this.array_di.publicaciones_relacionadas[0].titulo;
    var url= this.array_di.publicaciones_relacionadas[0].url;
    if(titulo!="" && url!=""){
      formData.append('publicaciones_relacionadas', JSON.stringify(this.array_di.publicaciones_relacionadas));
    }
    if(Object.keys(this.array_di.categorias).length !== 0){
      formData.append('categorias', JSON.stringify(this.array_di.categorias));
      console.log(JSON.stringify(this.array_di.categorias));
    }
    /* if(this.array_di.responsable.persona[0].apellido !== null && this.array_di.responsable.persona[0].nombre !== null){
      formData.append('persona', JSON.stringify(this.array_di.responsable.persona));
    }
    if(this.array_di.responsable.unidad[0].nombre_unidad !== null){
      formData.append('unidad', JSON.stringify(this.array_di.responsable.unidad));
    } */
    /* if(this.array_di.responsable.persona[0].apellido!== null && this.array_di.responsable.persona[0].nombre !== null){
      formData.append('responsable', JSON.stringify(this.array_di.responsable.persona));
    }
    if(this.array_di.responsable.unidad[0].nombre_unidad !== null){
      formData.append('responsable', JSON.stringify(this.array_di.responsable.unidad));
    } */
    /* Entrego archivos */
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i]);
    }

    this.http.post(this.dataGlobal.apiUploadInvestigacion, formData)
    .subscribe(response => {
console.log(response)
      if(response['resultado'] == "200"){
              this.login = false;
              this.msj = "success";
              //form.reset();
              this.array_di.archivos[0].archivo= null;
              this.array_di.archivos[0].fecha_embargo= "0000-00-00";
              this.array_di.concedo_licencia= false;
              console.log(response);
              
      }else if(response['resultado']== "403"){
              this.login = true;
              this.msj = false;
              this.errLog = true;
              this.array_di.clave = null;
              console.log("se conectó pero login es incorrecto");
              console.log(response);
              
      }else{
              this.msj = "error";
              form.reset();
              this.array_di.archivos[0].archivo= null;
              this.array_di.archivos[0].base64textString= null;
              this.array_di.concedo_licencia= false;
              console.log("se conectó pero no trajo resultado 200");
              console.log(response)
      }
    })

  }

  resetFile(){
    for (var i = 0; i < this.array_di.archivos.length; i++) { 
      this.array_di.archivos[i].archivo = null;
    }
  }

  newUpload(form){
    this.msj= "";
    this.activePane= 't1';
    location.reload();
  }

  toScroll(){
    document.getElementById('pasos').scrollIntoView({behavior: 'smooth'});
  }

  

}
